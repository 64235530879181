<template>
  <div class="container">
    <div class="aboutTpo">
      <el-image
        :src="require('@/assets/img/teamIntroduction/team_bg.png')"
        fit="fill"
        style="width: 100%; height: 100%;"
      ></el-image>
    </div>
    <div class="aboutContainer about-box flex flex-direction align-center">
      <el-image
        :src="require('@/assets/img/footer/about_title.png')"
        fit="fill"
        style="width: 76%; height: 100%;"
      ></el-image>
      <div style="padding-top: 2rem;" v-html="companyContent"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      /* 公司介绍内容 */
      companyContent: `<p style="line-height: 2;">
                        <span style="font-size: 20px;">概述</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        预见独角兽(北京)科技有限公司于2021年8月20日成立。
                      </p>

                      <p style="line-height: 2;">
                        <br/><span style="font-size: 20px;">公司介绍</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        2018年，为深入实施国家创新驱动战略，提前挖掘“独角兽”型创业人才和优质项目，预见独角兽（北京）科技有限公司通过一手抓优质项目、一手抓优质资本，打通对接渠道，培育、发掘和吸引一批具有“独角兽潜质”的创业项目，截至目前，已经形成了包括每天不间断的线上广播路演、每月北上深杭线下投融资路演、省级卫视投融资栏目、瞪羚/独角兽基金、创业实验室、榜单发布会、创投峰会、产业招商服务等在内的科创生态服务平台，成为全国有重要影响力的创投IP。
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        预见独角兽是中国领先的创投平台，已服务企业超100000家。知名投资人、专家、导师库超10000人。旗下基金覆盖企业早、中、后期各个阶段，同时与梅花创投、红杉中国等超百家国内优秀投资机构达成联合投资深度合作，一年已帮助近百个项目获得融资超30亿元。重点发掘了人工智能、VR/AR、新能源与新材料、大数据、云计算等众多新兴赛道的企业。与东方卫视和江西卫视联袂打造的《预见独角兽》节目、一年一度的全国创投峰会、独角兽特训营正在帮助越来越多优秀的创业者成为伟大的企业家。
                      </p>
                      <p style="line-height: 2;">
                        <br/><span style="font-size: 20px;">公司文化</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        以“成为最受尊敬的创投平台”为愿景，以“预见未来创新领袖”为使命
                      </p>

                      <p style="line-height: 2;text-indent: 2rem;">
                        <br/><span style="font-size: 20px;">公司产品</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        《预见独角兽》卫视栏目：
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        独角兽项目发展困难重重，如何解决项目融资难、市场壁垒高、招商加盟难、品牌影响力弱等问题迫在眉睫。为此，预见独角兽联合东方卫视、江西卫视、梅花创投、红杉资本、金沙江创投等超百家国内一线资机构、网络达人进行深度合作，打造创投类大型真人秀栏目《预见独角兽·青创英雄汇》栏目。树立行业标杆，帮助越来越多优秀的创业者成为伟大的企业家。
                        第一季已经播出12月、累计10000+企业报名参加、1000+投资机构参与、覆盖城市40余座、聚焦产业30+、累积融资超30亿。
                        <a style="color: #f08519;" href="https://baijiahao.baidu.com/s?id=1759219709646763012&wfr=spider&for=pc">栏目启动仪式</a>
                        <a style="color: #f08519;margin-left:10px;" href="https://www.jxcbw.cn/newsContent?newsid=1637753683896725505">《预见独角兽》第二季首播</a>
                      </p>

                      <p style="line-height: 2;">
                        <br/><span style="font-size: 20px;">独角兽训练营简介</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        预见独角兽训练营是由中科心客、梅花创投联合众多知名企业创始人、知名投资机构联合推出的一项面对企业创始人、苹事长、总裁、总经理、高管的认知课程，目前已有导师团队投资人吴世春、周逵，知名企业家程维、傅盛等。
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        预见独角兽训练营以“名师招徒”的模式，让创业者与导师形成紧密的“师徒”关系，促使“师傅”利用自身认知和广泛的资源帮助“徒弟”提升创业成功概率，通过导师引路，解决创业难题。同时，让同量级的创业者与创业者之间形成高质量的“同窗”关系，不断扩充人脉，形成相互之间正能量推动的圈层社交，从提升创业者的投融资能力、市场生存能力、开拓创新能力、经营管理能力出发，帮助更多的创业者实现创业梦想。
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        2020年12月，预见独角兽训练营第一季吴世春导师班已开班，共有20名学员，其中知名学员有金力永磁董事李忻农、中科心客创始人缪金生、若贝特创始人兼CEO孟强等，学员项目已有一半获得投资，课程效果得到学员的充分肯定。
                      </p>
                      <p style="line-height: 2;">
                        <br/><span style="font-size: 20px;">预见独角兽创投峰会</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        2021年5月29日，习近平总书记在两院院士大会中国科协第十次全国代表大会上指出：当今世界百年未有之大变局加速演进，新一轮科技革命和产业变革突飞猛进，科技创新成为国际战略博弈的主要战场，围绕科技制高点的竞争空前激烈。在新一轮世界科技革命和产业变革大势下，科技创新在国家发展全局的核心位置日益凸显。在创新驱动下，中国经济高质量发展动能强劲。期间更涌现出一批批推动创新驱动，走在时代前沿的高成长企业。在此背景下，预见独角兽联合政府、资本、权威机构举办一年一度的创投盛会，通过发布《预见独角兽榜单》及《独角兽企业研究报告》对接产业落地。
                      </p>
                      <p style="line-height: 2;">
                        <br/><span style="font-size: 20px;">预见独角兽培育服务</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        依托“预见独角兽”丰富的项目资源及投资机构资源，以及培育创新企业、独角兽企业的成功经验到全国100个城市落地生根，助力地方城市产业创新升级，为政府、投资机构、产业园区、龙头企业开展对接，为政府部门高效管理赋能，为园区招商引智赋能，为企业创新转型赋能，助推科技产业发展，加速科技成果转化。
                      </p>
                      <p style="line-height: 2;">
                        【赛事合作】
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        依托“预见独角兽”丰富的项目资源及投资机构资源，已举办历届科技厅、人社厅、教育厅等各类创新创业大赛，通过赛事活动挖掘优秀的创业项目，通过培育、投融资对接等方式助推企业成长。
                      </p>
                      <p style="line-height: 2;">
                        【区域科创协同整体解决方案】
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        构建区域内企业、园区、产业等创新要素数据平台应用大数据、人工智能等技术，洞悉区域产业情况精准配置资源，为地方政府产业培育及升级服务，高效管理赋能，招商引智赋能，为企业创新转型赋能
                      </p>
                      <p style="line-height: 2;">
                        【科技大市场运营】
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        从技术转移规划设计、线上平台建设与运营、线下服务体系建设、供需资源挖掘集聚、要素资源导入与运营、服务机构引入与管理等方面为科技市场运营提供全面的支撑与服务。
                      </p>
                      <p style="line-height: 2;">
                        【榜单评选】
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        为了寻找和发现全国新经济领域的翘楚者，嫁接政府、银行、投资机构等多方资源，加速企业成长，预见独角兽研究院联合各大企业、当地政府推出《年度独角兽(潜在、种子)、瞪羚(潜在)企业》等多个榜单。
                      </p>
                      <p style="line-height: 2;">
                        <br/><span style="font-size: 20px;">心客基金</span>
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        江西心客投资有限公司是中科心客旗下核心投资平台，是江西省率先获得中国基金业协会登记和备案的私募基金管理人(登记编号:P1066539)，是江西省投资基金业协会常务副会长单位，发起设立了心客春之雨、心客筑享、南昌工控基金、心客预见基金、心客中基池州产业基金、江西省科技创新基金等多支基金，获得了众多政府引导基金的青睐和支持，凭借专业的募、投、管、退团队和丰富的项目经验及资源，投资了码牛科技、诺客环境、猎户星空、宝弘新材、FILL耳机、博迅汽车、双天使生物科技等众多优质项目，成为江西省创投的名片。
                      </p>
                      <p style="line-height: 2;text-indent: 2rem;">
                        目前江西心客投资有限公司主要围绕价值投资+成长型投资展开长期股权投资业务，基金主要投资于新能源、新材料、汽车产业链、TMT、先进制造、人工智能、大数据、大健康等领域。
                      </p>
                     `
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: -1rem;
  padding-bottom: 4rem;
}

.about-box {
  position: relative;
  margin-top: -3rem;
  padding: 3rem 4rem 4rem;
  border: 1px solid #f3f3f3;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 2px 2px 10px #eee;
}
.aboutContainer{
  width: 1200px;
  margin: 0 auto;
  padding: 40px;
}
.aboutTpo{
  width: 100%;
  height: 27.625rem;
}
@media screen and (max-width: 1500px) {
  .aboutContainer{
    width: 95%;
    padding: 0 20px;
  }
  .aboutTpo{
    width: 100%;
    height: auto;
  }
}
</style>
